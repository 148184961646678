import React from "react"; // Importa a biblioteca React
import "../BetdaSorte/Card.css"; // Importa o arquivo CSS específico para o componente Card
import logo from "../BetdaSorte/Logo.png"; // Importa a imagem do logo
import pix from "../../img/Formas_de_Pagamento/1.Pix.png"; // Importa a imagem do método de pagamento Pix
import mastercard from "../../img/Formas_de_Pagamento/2.Mastercard.png"; // Importa a imagem do método de pagamento Mastercard
import visa from "../../img/Formas_de_Pagamento/3.Visa.png"; // Importa a imagem do método de pagamento Visa
import p4f from "../../img/Formas_de_Pagamento/4.P4F.png"; // Importa a imagem do método de pagamento P4F

// Define o componente Card como uma função
const BetdaSorte = () => {
  return (
    <a
      href="https://betdasorte.com/ac/betconecta"
      target="_blank"
      rel="noopener noreferrer"
      className="cartao-aposta"
    >
      {/* Define um link com a classe "cartao-aposta" */}
      <div className="secao-logo">
        {/* Define a seção do logo */}
        <img src={logo} alt="Logo BetdaSorte" className="logo-betdasorte" />
        {/* Adiciona a imagem do logo com a classe "logo-betdasorte" */}
      </div>
      <div className="secao-bonus">
        {/* Define a seção do bônus */}
        <p>Bônus</p>
        <h2 className="quantidade-bonus">Deposite R$ 50,00</h2>
        {/* Define a porcentagem do bônus */}
        <h3 className="quantidade-bonus">Ganhe R$ 100,00</h3>
        {/* Define o valor máximo do bônus */}
      </div>
      <div className="secao-metodos-pagamento">
        {/* Define a seção dos métodos de pagamento */}
        <img src={pix} alt="Pix" className="metodo-pix" />
        {/* Adiciona a imagem do Pix com a classe "metodo-pix" */}
      </div>
      <div className="secao-avaliacao">
        {/* Define a seção de avaliação */}
        <p>Validando... </p> {/* Exibe a nota de avaliação */}
      </div>
      <div className="secao-acao">
        {/* Define a seção de ação */}
        <button className="botao-apostar">APOSTAR AGORA</button>
        {/* Adiciona o botão de aposta com a classe "botao-apostar" */}
      </div>
    </a>
  );
};

export default BetdaSorte; // Exporta o componente Card como padrão

