import React from "react"; // Importa a biblioteca React
import "../Bet69/Card.css"; // Importa o arquivo CSS específico para o componente Cartão
import logo from "../Bet69/Logo.png"; // Importa a imagem do logo
import pix from "../../img/Formas_de_Pagamento/1.Pix.png"; // Importa a imagem do método de pagamento Pix
import mastercard from "../../img/Formas_de_Pagamento/2.Mastercard.png"; // Importa a imagem do método de pagamento Mastercard
import visa from "../../img/Formas_de_Pagamento/3.Visa.png"; // Importa a imagem do método de pagamento Visa
import p4f from "../../img/Formas_de_Pagamento/4.P4F.png"; // Importa a imagem do método de pagamento P4F

// Define o componente Cartão como uma função
const Bet69 = () => {
  return (
    <a
      href="https://www.bet69pro.bet/register?id=669e671c70047a00285edc5f"
      target="_blank"
      rel="noopener noreferrer"
      className="cartao-aposta"
    >
      {" "}
      {/* Define um link com a classe "cartao-aposta" */}
      <div className="secao-logo">
        {" "}
        {/* Define a seção do logo */}
        <img src={logo} alt="Exemplo" className="logo-bet69" />{" "}
        {/* Adiciona a imagem do logo com a classe "logo-bet69" */}
      </div>
      <div className="secao-bonus">
        {" "}
        {/* Define a seção do bônus */}
        <p>Bônus</p>
        <h2 className="quantidade-bonus">100%</h2>{" "}
        {/* Define a porcentagem do bônus */}
        <h3 className="quantidade-bonus">até R$100,00</h3>{" "}
        {/* Define o valor máximo do bônus */}
      </div>
      <div className="secao-metodos-pagamento">
        {" "}
        {/* Define a seção dos métodos de pagamento */}
        <img src={pix} alt="Pix" className="metodo-pix" />{" "}
        {/* Adiciona a imagem do Pix com a classe "metodo-pix" */}        
      </div>
      <div className="secao-avaliacao">
        {" "}
        {/* Define a seção de avaliação */}
        <p>Validando... </p> {/* Exibe a nota de avaliação */}
      </div>
      <div className="secao-acao">
        {" "}
        {/* Define a seção de ação */}
        <button className="botao-apostar">APOSTAR AGORA</button>{" "}
        {/* Adiciona o botão de aposta com a classe "botao-apostar" */}
      </div>
    </a>
  );
};

export default Bet69; // Exporta o componente Cartão como padrão

