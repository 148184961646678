import React from "react"; // Importa a biblioteca React
import "../Header/Header.css"; // Importa o arquivo CSS específico para o componente Header
import logo from "../img/Logo.png"; // Importa a imagem do logo
import { Link } from "react-router-dom"; // Importa o componente Link do react-router-dom para navegação

// Define o componente Header como uma função
function Header() {
  return (
    <header className="Header">
      {" "}
      {/* Define o elemento header com a classe "Header" */}
      <div className="logo-container">
        {" "}
        {/* Define um contêiner para o logo */}
        <img src={logo} alt="Bet Conecta" className="logo" />{" "}
        {/* Adiciona a imagem do logo com uma classe "logo" */}
      </div>
      <nav className="nav">
        {" "}
        {/* Define o elemento nav com a classe "nav" */}
        <ul className="nav-list">
          {" "}
          {/* Define uma lista não ordenada com a classe "nav-list" */}
          <li className="nav-item">
            {" "}
            {/* Define um item da lista com a classe "nav-item" */}
            <Link to="/" className="nav-link">
              {" "}
              {/* Link para a página inicial com a classe "nav-link" */}
              Home
            </Link>
          </li>
          <li className="nav-item">
            {" "}
            {/* Define um item da lista com a classe "nav-item" */}
            <Link to="/jogoresponsavel" className="nav-link">
              {" "}
              {/* Link para a página de Jogo Responsável */}
              Jogo Responsável
            </Link>
          </li>
          <li className="nav-item">
            {" "}
            {/* Define um item da lista com a classe "nav-item" */}
            <Link to="/politicaprivacidade" className="nav-link">
              {" "}
              {/* Link para a página de Política de Privacidade */}
              Política Privacidade
            </Link>
          </li>
          <li className="nav-item">
            {" "}
            {/* Define um item da lista com a classe "nav-item" */}
            <Link to="/contato" className="nav-link">
              {" "}
              {/* Link para a página de Contato */}
              Contato
            </Link>
          </li>
        </ul>
      </nav>
      <div className="update-info">
        {" "}
        {/* Define um contêiner para a informação de atualização */}
        <span>
          Última Atualização
          <br />
          Julho 2024
        </span>
      </div>
    </header>
  );
}

export default Header; // Exporta o componente Header como padrão
