// Importa os componentes necessários do React Router para configurar as rotas da aplicação
import { BrowserRouter, Route, Routes } from "react-router-dom";

// Importa os componentes das páginas que serão renderizadas quando suas respectivas rotas forem acessadas
import Home from "../src/pages/Home/index.js";
import JogoResponsavel from "./pages/JogoResponsavel/index.js";
import PoliticadePrivacidade from "./pages/PoliticadePrivacidade/index.js";
import Contato from "../src/pages/Contato/index.js";

// Define o componente funcional AppRoutes que renderiza as rotas da aplicação
function AppRoutes() {
  return (
    // Utiliza BrowserRouter para fornecer a funcionalidade de roteamento baseado em histórico
    <BrowserRouter>
      {/* Utiliza Routes para definir o agrupamento de rotas */}
      <Routes>
        {/* Define a rota para a página inicial, renderizando o componente Home */}
        <Route path="/" element={<Home />}></Route>
        {/* Define a rota para a página JogoResponsavel, renderizando o componente JogoResponsavel */}
        <Route path="/JogoResponsavel" element={<JogoResponsavel />}></Route>
        {/* Define a rota para a página PoliticaPrivacidade, renderizando o componente PoliticadePrivacidade */}
        <Route
          path="/PoliticaPrivacidade"
          element={<PoliticadePrivacidade />}
        ></Route>
        {/* Define a rota para a página Contato, renderizando o componente Contato */}
        <Route path="/Contato" element={<Contato />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

// Exporta o componente AppRoutes para que possa ser utilizado em outras partes da aplicação
export default AppRoutes;
