import React from "react";
import Header from "../../components/Header/Header.js"; // Importa o componente Header
import Banner from "../../components/Banner/Banner.js"; // Importa o componente Banner
import About from "../../components/About/About.js"; // Importa o componente About
import Bet69 from "../../components/Cards/Bet69/Card.js"; // Importa o componente Card
import BetdaSorte from "../../components/Cards/BetdaSorte/Card.js";
import Footer from "../../components/Footer/Footer.js"; // Importa o componente Footer

function Home() {
  return (
    <div className="Home">
      {" "}
      {/* Define a div principal com a classe CSS "Home" */}
      <Header /> {/* Renderiza o componente Header */}
      <Banner /> {/* Renderiza o componente Banner */}
      <About /> {/* Renderiza o componente About */}
      <Bet69 /> {/* Renderiza o componente Card */}
      <BetdaSorte /> {/* Renderiza o componente Card */}      
      <Footer /> {/* Renderiza o componente Footer */}
    </div>
  );
}

export default Home; // Exporta o componente Home
