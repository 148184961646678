import React from "react";
import "./style.css"; // Importa os estilos personalizados para o componente Jg_Responsavel
import Header from "../../components/Header/Header.js"; // Importa o componente Header
import Banner from "../../components/Banner/Banner.js"; // Importa o componente Banner
import Footer from "../../components/Footer/Footer.js"; // Importa o componente Footer

function Jg_Responsalvel() {
  return (
    <div className="Jg_Responsavel">
      {" "}
      {/* Define a div principal com a classe CSS "Jg_Responsavel" */}
      <Header /> {/* Renderiza o componente Header */}
      <Banner /> {/* Renderiza o componente Banner */}
      <h2>Jogo Responsável</h2>
      <p>
        Quando o entretenimento das apostas começa a causar problemas, é hora de
        ligar o sinal de alerta e buscar ajuda. Para prevenir esses problemas, a
        Caixa Econômica Federal adota um conjunto de ações conhecido como jogo
        responsável, garantindo que os apostadores possam se divertir de forma
        segura.
      </p>
      <h2>Consciência e Responsabilidade</h2>
      <p>
        Todos que apostam na loteria, seja online ou fisicamente, devem estar
        conscientes da importância do jogo responsável. Essa prática ajuda a
        recuperar pessoas que enfrentam problemas relacionados ao jogo,
        promovendo a saúde mental e contribuindo para uma diversão segura e
        equilibrada.
      </p>
      <h2>Medidas de Jogo Responsável</h2>
      <p>
        Uma das medidas mais importantes é a proibição de apostas por menores de
        idade. Apenas pessoas com 18 anos ou mais podem participar das loterias,
        garantindo que somente adultos tenham acesso aos jogos de azar. Além
        disso, o jogo responsável combate o jogo compulsivo, que pode levar a
        sérios distúrbios e colocar vidas em risco. A administradora dos jogos
        orienta aqueles que apresentem sinais de comportamento compulsivo a
        procurar programas de saúde para tratamento imediato. A conscientização
        sobre os valores apostados também é crucial. Nunca aposte mais do que
        pode perder ou dinheiro destinado a outras necessidades, como pagamento
        de contas. Aposte apenas valores que não comprometam seu bem-estar
        financeiro.
      </p>
      <h2>Práticas Internacionais</h2>
      <p>
        Para garantir suporte e segurança, a Caixa Econômica Federal segue
        diretrizes internacionais da Associação Mundial de Loterias (World
        Lottery Association), modelo adotado por outros 76 países.
      </p>
      <h2>Mantendo Hábitos Responsáveis</h2>
      <p>
        Apostar pode ser um passatempo emocionante. No entanto, é essencial
        manter hábitos responsáveis. A sensação de ganhar é incrível, mas pode
        levar ao vício se não houver controle.
      </p>
      <h2>Entendendo a Ludomania</h2>
      <p>
        A ludomania é o vício em jogos, incluindo jogos de azar. Esse vício pode
        causar sérios problemas na vida pessoal, familiar e profissional. Aqui
        estão algumas perguntas para refletir sobre seu comportamento em relação
        ao jogo:
      </p>
      <ul className="bullet-list">
        <li>Você sente uma necessidade crescente de apostar?</li>
        <li>Tem vergonha de falar sobre seus hábitos de aposta?</li>
        <li>Sente ansiedade ao apostar?</li>
        <li>Consegue parar de apostar?</li>
        <li>Já tentou parar de apostar? Por quanto tempo?</li>
        <li>Sente-se depressivo ou ansioso quando não está apostando?</li>
      </ul>
      <p>
        Responda a essas perguntas com sinceridade para entender melhor o
        impacto das apostas em sua vida.
      </p>
      <h2>Procurando Ajuda</h2>
      <p>
        Se você acredita que tem um vício em apostas, procure entender a causa
        desse vício. Muitas vezes, as apostas servem como uma válvula de escape
        para problemas do dia a dia. Nesse caso, é importante buscar ajuda de
        psicólogos e terapeutas. Se você não acredita ter um vício, continue
        mantendo uma relação saudável com as apostas e aproveite esse hobby de
        forma responsável. Apostar pode ser divertido e emocionante, desde que
        seja feito com responsabilidade e consciência.
      </p>
      <Footer /> {/* Renderiza o componente Footer */}
    </div>
  );
}

export default Jg_Responsalvel; // Exporta o componente Jg_Responsavel
