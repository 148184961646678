import React from "react"; // Importa a biblioteca React
import "./Footer.css"; // Importa o arquivo CSS específico para o componente Footer
import imagem1 from "../img/1.png"; // Importa a primeira imagem
import imagem2 from "../img/2.png"; // Importa a segunda imagem
import imagem3 from "../img/3.png"; // Importa a terceira imagem
import imagem4 from "../img/4.png"; // Importa a quarta imagem

// Define o componente Footer como uma função
function Footer() {
  return (
    <section id="contact" className="Footer">
      {" "}
      {/* Define uma seção com ID "contact" e classe "Contact" */}
      <div className="imagem">
        {" "}
        {/* Define um contêiner para as imagens */}
        <a
          href="https://ibjr.org/jogo-responsavel/boas-praticas-jogadores/" // Define o link para boas práticas de jogadores do IBJR
          target="_blank" // Abre o link em uma nova aba
          rel="noopener noreferrer" // Melhora a segurança ao abrir links em uma nova aba
        >
          <img src={imagem1} alt="IBJR" />{" "}
          {/* Adiciona a primeira imagem com o texto alternativo "IBJR" */}
        </a>
        <a
          href="https://www.gambleaware.org/" // Define o link para o site Be Gamble Aware
          target="_blank" // Abre o link em uma nova aba
          rel="noopener noreferrer" // Melhora a segurança ao abrir links em uma nova aba
        >
          <img src={imagem2} alt="Be Gamble Aware" />{" "}
          {/* Adiciona a segunda imagem com o texto alternativo "Be Gamble Aware" */}
        </a>
        <a
          href="https://www.gamcare.org.uk/" // Define o link para o site Gam Care
          target="_blank" // Abre o link em uma nova aba
          rel="noopener noreferrer" // Melhora a segurança ao abrir links em uma nova aba
        >
          <img src={imagem3} alt="Gam Care" />{" "}
          {/* Adiciona a terceira imagem com o texto alternativo "Gam Care" */}
        </a>
        <img src={imagem4} alt="Maior de 18 Anos" />{" "}
        {/* Adiciona a quarta imagem com o texto alternativo "Maior de 18 Anos" */}
        <h3>
          BetConecta 2024 © Copyright. Todos os direitos reservados a seus
          autores.
        </h3>{" "}
        {/* Adiciona um texto de copyright */}
      </div>
    </section>
  );
}

export default Footer; // Exporta o componente Footer como padrão
