import React from "react";
import "./style.css"; // Importa os estilos personalizados para o componente Contato
import Header from "../../components/Header/Header.js"; // Importa o componente Header
import Banner from "../../components/Banner/Banner.js"; // Importa o componente Banner
import Footer from "../../components/Footer/Footer.js"; // Importa o componente Footer

function Contato() {
  return (
    <div className="Contato">
      {" "}
      {/* Define a div principal com a classe CSS "Contato" */}
      <Header /> {/* Renderiza o componente Header */}
      <Banner /> {/* Renderiza o componente Banner */}
      <h2>Contato</h2> {/* Título principal da seção */}
      <p className="Texto">
        {" "}
        {/* Parágrafo com a classe CSS "Texto" */}
        Ficou com alguma dúvida ou precisa falar com alguém da nossa equipe? É
        simples, basta enviar um email para contato@betconecta.com.br,
        informando o que precisa que teremos o prazer em te ajudar respondendo o
        mais rápido possível.
      </p>
      <Footer /> {/* Renderiza o componente Footer */}
    </div>
  );
}

export default Contato; // Exporta o componente Contato
