import React from "react";
import "../About/About.css"; // Importa o arquivo de estilos personalizados para o componente About

function About() {
  return (
    <section id="about" className="About">
      {" "}
      {/* Define a seção "about" com a classe CSS "About" */}
      <div className="column">
        {" "}
        {/* Define uma coluna */}
        <h2>Bet Conecta Indica</h2> {/* Título principal */}
        <p>
          Encontre as melhores ofertas de bônus nas principais casas de apostas
          online. Todas as nossas recomendações são licenciadas, 100% seguras e
          confiáveis. Aproveite os bônus exclusivos e comece a se divertir
          agora!
        </p>
        <h2>Por que Confiar nas Nossas Recomendações?</h2> {/* Subtítulo */}
        <p>
          Na Bet Conecta, garantimos transparência e excelência. Nossas
          avaliações rigorosas asseguram que as casas de apostas recomendadas
          ofereçam segurança e as melhores condições para apostadores.
        </p>
      </div>
      <div className="column">
        {" "}
        {/* Define outra coluna */}
        <h2>O que você vai encontrar</h2> {/* Título principal */}
        <ul className="text-about">
          {" "}
          {/* Lista não ordenada com a classe CSS "text-about" */}
          <li>Bônus de Boas-Vindas: Ofertas incríveis ao se cadastrar.</li>{" "}
          {/* Item da lista */}
          <li>
            Promoções Exclusivas: Bônus adicionais e promoções especiais.
          </li>{" "}
          {/* Item da lista */}
          <li>
            Licenciamento e Segurança: Casas de apostas licenciadas e seguras.
          </li>{" "}
          {/* Item da lista */}
          <li>Suporte ao Cliente: Atendimento rápido e eficiente.</li>{" "}
          {/* Item da lista */}
        </ul>
        <h2>Comece a Sua Jornada de Apostas com o Pé Direito</h2>{" "}
        {/* Título principal */}
        <p>
          Aproveite as melhores ofertas de bônus do Brasil com as recomendações
          da Bet Conecta. Transforme sua experiência de apostas em algo seguro,
          divertido e lucrativo!
        </p>
      </div>
    </section>
  );
}

export default About; // Exporta o componente About
