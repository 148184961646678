import React from "react";
// Importações necessárias do Swiper
import "swiper/css"; // Importa o CSS básico do Swiper
import "swiper/css/navigation"; // Importa o CSS da navegação do Swiper
import "swiper/css/pagination"; // Importa o CSS da paginação do Swiper
import "swiper/css/autoplay"; // Importa o CSS do autoplay do Swiper
import "../Banner/Banner.css"; // Importação dos estilos personalizados
// Importação das imagens do banner
import imagem1 from "../img/Slide_1.png"; // Importa a imagem do primeiro slide
import imagem2 from "../img/Slide_2.png"; // Importa a imagem do segundo slide
import imagem3 from "../img/Slide_3.png"; // Importa a imagem do terceiro slide
import imagem4 from "../img/Slide_4.png"; // Importa a imagem do quarto slide
import imagem5 from "../img/Slide_5.png"; // Importa a imagem do quinto slide
// Importação dos componentes do Swiper
import { Swiper, SwiperSlide } from "swiper/react"; // Importa os componentes Swiper e SwiperSlide
import { Navigation, Pagination, Autoplay } from "swiper/modules"; // Importa os módulos de navegação, paginação e autoplay do Swiper

function Banner() {
  // Array de objetos contendo as imagens dos slides
  const data = [
    { id: "1", src: imagem1 }, // Objeto para o primeiro slide
    { id: "2", src: imagem2 }, // Objeto para o segundo slide
    { id: "3", src: imagem3 }, // Objeto para o terceiro slide
    { id: "4", src: imagem4 }, // Objeto para o quarto slide
    { id: "5", src: imagem5 }, // Objeto para o quinto slide
  ];

  return (
    <div className="container">
      {" "}
      {/* Container para o Swiper */}
      <Swiper
        modules={[Navigation, Pagination, Autoplay]} // Módulos do Swiper utilizados
        slidesPerView={1} // Número de slides visíveis por vez
        pagination={{ clickable: true }} // Habilita a paginação clicável
        navigation // Habilita a navegação pelos botões
        autoplay={{ delay: 3000 }} // Configura o autoplay com um delay de 3 segundos
      >
        {data.map((item) => (
          <SwiperSlide key={item.id}>
            {" "}
            {/* Cada slide do Swiper */}
            <img
              src={item.src} // Fonte da imagem
              alt={`Slide ${item.id}`} // Texto alternativo para a imagem
              className="slide-item" // Classe CSS para estilização da imagem
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default Banner; // Exportação do componente Banner
