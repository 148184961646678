import React from "react";
import "./style.css";
import Header from "../../components/Header/Header.js";
import Banner from "../../components/Banner/Banner.js";
import Footer from "../../components/Footer/Footer.js";

function PoliticadePrivacidade() {
  return (
    <div className="PoliticaPrivacidade">
      <Header />
      <Banner />
      <h2>Política de Privacidade da Bet Conecta</h2>
      <p>
        A sua privacidade é essencial para nós. Na Bet Conecta, comprometemo-nos
        a respeitar a sua privacidade em relação a qualquer informação que
        possamos coletar no nosso site e em outros sites que possuímos e
        operamos.
      </p>

      <h2>Coleta e Uso de Informações Pessoais</h2>
      <p>
        Solicitamos informações pessoais apenas quando realmente precisamos
        delas para lhe fornecer um serviço. Fazemos isso por meios justos e
        legais, com o seu conhecimento e consentimento. Também informamos por
        que estamos coletando essas informações e como serão utilizadas. Retemos
        as informações coletadas apenas pelo tempo necessário para fornecer o
        serviço solicitado. Quando armazenamos dados, protegemos dentro de meios
        comercialmente aceitáveis para evitar perdas, roubos, bem como acesso,
        divulgação, cópia, uso ou modificação não autorizados.
      </p>

      <h2>Compartilhamento de Informações</h2>
      <p>
        Não compartilhamos informações de identificação pessoal publicamente ou
        com terceiros, exceto quando exigido por lei. Nosso site pode conter
        links para sites externos que não são operados por nós. Esteja ciente de
        que não temos controle sobre o conteúdo e práticas desses sites e não
        podemos aceitar responsabilidade por suas respectivas políticas de
        privacidade.
      </p>

      <h2>Consentimento e Direito de Recusa</h2>
      <p>
        Você é livre para recusar nossa solicitação de informações pessoais,
        entendendo que talvez não possamos fornecer alguns dos serviços
        desejados. O uso continuado de nosso site será considerado como
        aceitação de nossas práticas em torno de privacidade e informações
        pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do
        usuário e informações pessoais, entre em contato conosco.
      </p>

      <h1>Política de Cookies da Bet Conecta</h1>

      <h2>O que são cookies?</h2>
      <p>
        Como é prática comum em quase todos os sites profissionais, este site
        usa cookies, que são pequenos arquivos baixados no seu computador, para
        melhorar sua experiência. Esta página descreve quais informações eles
        coletam, como as usamos e por que às vezes precisamos armazenar esses
        cookies. Também compartilharemos como você pode impedir que esses
        cookies sejam armazenados, no entanto, isso pode fazer o downgrade ou
        'quebrar' certos elementos da funcionalidade do site.
      </p>

      <h2>Como usamos os cookies?</h2>
      <p>
        Utilizamos cookies por vários motivos, detalhados abaixo. Infelizmente,
        na maioria dos casos, não existem opções padrão do setor para desativar
        os cookies sem desativar completamente a funcionalidade e os recursos
        que eles adicionam a este site. É recomendável que você deixe todos os
        cookies se não tiver certeza se precisa ou não deles, caso sejam usados
        para fornecer um serviço que você usa.
      </p>

      <h2>Desativar cookies</h2>
      <p>
        Você pode impedir a configuração de cookies ajustando as configurações
        do seu navegador (consulte a Ajuda do navegador para saber como fazer
        isso). Esteja ciente de que a desativação de cookies afetará a
        funcionalidade deste e de muitos outros sites que você visita. A
        desativação de cookies geralmente resultará na desativação de
        determinadas funcionalidades e recursos deste site. Portanto, é
        recomendável que você não desative os cookies.
      </p>

      <h2>Cookies que definimos</h2>
      <ul className="bullet-list">
        <li>
          Cookies relacionados à conta: Usamos cookies para o gerenciamento do
          processo de inscrição e administração geral. Esses cookies geralmente
          serão excluídos quando você sair do sistema, porém, em alguns casos,
          podem permanecer para lembrar as preferências do site ao sair.
        </li>
        <li>
          Cookies relacionados ao login: Utilizamos cookies quando você está
          logado para lembrar dessa ação, evitando que precise fazer login a
          cada nova página visitada. Esses cookies são normalmente removidos ou
          limpos quando você efetua logout.
        </li>
        <li>
          Cookies relacionados a boletins por e-mail: Este site oferece serviços
          de assinatura de boletim informativo e os cookies podem ser usados
          para lembrar se você já está registrado e se deve mostrar determinadas
          notificações válidas apenas para usuários inscritos/não inscritos.
        </li>
        <li>
          Pedidos processando cookies relacionados: Alguns cookies são
          essenciais para garantir que seu pedido seja lembrado entre as páginas
          e processado adequadamente.
        </li>
        <li>
          Cookies relacionados a pesquisas: Oferecemos pesquisas e questionários
          periodicamente, e esses cookies lembram quem já participou ou fornecem
          resultados precisos após a alteração das páginas.
        </li>
        <li>
          Cookies relacionados a formulários: Quando você envia dados por meio
          de um formulário, os cookies podem lembrar os detalhes do usuário para
          correspondência futura.
        </li>
        <li>
          Cookies de preferências do site: Para proporcionar uma ótima
          experiência, fornecemos a funcionalidade para definir suas
          preferências de como o site é executado, lembradas através de cookies.
        </li>
      </ul>

      <h2>Cookies de Terceiros</h2>
      <p>
        Em alguns casos, usamos cookies fornecidos por terceiros confiáveis,
        como o Google Analytics, para entender como você usa o site e como
        podemos melhorar sua experiência. Esses cookies rastreiam itens como o
        tempo gasto no site e as páginas visitadas.
      </p>

      <h2>Compromisso do Usuário</h2>
      <p>
        O usuário se compromete a fazer uso adequado dos conteúdos e informações
        fornecidas pela Bet Conecta, não se envolvendo em atividades ilegais ou
        contrárias à boa fé e à ordem pública, não difundindo propaganda ou
        conteúdo ofensivo, e não causando danos aos sistemas físicos e lógicos
        do site.
      </p>

      <h2>Mais Informações</h2>
      <p>
        Esperamos que esta política tenha esclarecido questões sobre a
        privacidade e uso de cookies. Caso tenha alguma dúvida ou precise de
        mais informações, entre em contato conosco. Esta política é efetiva a
        partir de janeiro de 2021.
      </p>
      <Footer />
    </div>
  );
}

export default PoliticadePrivacidade;
